import styled from "styled-components";

const Spacer = styled("div")`
	display: flex;
	flex: 1;

	${({ flexBasis }) => flexBasis && `flex-basis: 100%`}
`;

Spacer.displayName = "Spacer";

export default Spacer;
